import { useSnackbar } from 'notistack';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'utils';
import { getUserSettingsApi, loginUserApi, updateUserSettingsApi } from 'utils/constants/apis';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_LANGUAGE } from 'utils/constants/app';
import { LS_EXPIRATION_TOKEN, LS_TOKEN } from 'utils/constants/localStorage';
import axiosInstance from 'utils/helpers/axios';
import { jwtDecode } from "jwt-decode"
import { ADMIN_ROLE_ID } from '../utils/constants/auth';

const UserContext = createContext({
  loading: false,
  auth: null,
  handleLogin: noop,
  updateSettings: noop,
  getSettings: noop,
  clearSession: noop,
});

export const useUser = ()  => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const defaultAuth = localStorage.getItem(LS_EXPIRATION_TOKEN) || null;
  const [auth, setAuth] = useState(defaultAuth);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);

  const { t } = useTranslation('app');

  const handleLogin = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(loginUserApi(), payload)
      .then(response => {
        const {expiration, token} = response.data;
        const decodedToken = jwtDecode(token);

        enqueueSnackbar(t('Login successful'), {
          variant: 'success',
        });

        localStorage.setItem(LS_TOKEN, token);
        localStorage.setItem(LS_EXPIRATION_TOKEN, expiration);
        setAuth(token);
        onSuccess(response.data);
      })
      .catch(err => {
        enqueueSnackbar(t('Incorrect email or password') || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        localStorage.removeItem(LS_TOKEN);
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const clearSession = () => {
    console.log('clearSession');
    localStorage.removeItem(LS_TOKEN);
    localStorage.clear();
    console.log('localStorage LS_USER_ID', localStorage.getItem(LS_TOKEN));
    setAuth(null);
  };

  const getSettings = (onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .get(getUserSettingsApi(`/${auth}`))
      .then(data => {
        const { timezone, language } = data;
        //  setTimezone(timezone);
        setLanguage(language);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const updateSettings = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(updateUserSettingsApi(), payload)
      .then(data => {
        enqueueSnackbar(t('Updated settings successfully'), {
          variant: 'success',
        });
        const { language } = data;
        //  setTimezone(timezone);
        setLanguage(language);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
        setLanguage(language);
        onSuccess()
      });
  };

  const contextValue = {
    loading,
    auth,
    handleLogin,
    updateSettings,
    getSettings,
    clearSession,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
