export const HOME = '/admin';
export const DASHBOARD = '/admin/dashboard';
export const DASHBOARD_JOB_POSTED = '/admin/dashboard/job-posted';
export const ROLE = '/admin/role';
export const ERP_TOHAMA_ADMIN = '/admin/erp-tohama-admin';
export const MANAGE_SUBSCRIPTIONS_PLANS = '/admin/manage-university/plans';
export const MANAGE_SUBSCRIPTIONS_ADD_PLAN =
  '/admin/manage-university/plans/add';
export const MANAGE_SUBSCRIPTIONS_SUBSCRIBERS =
  '/admin/manage-university/subscribers';
export const MANAGE_SUBSCRIPTIONS_CONFIGURE =
  '/admin/manage-university/university-list';
export const MANAGE_COMPANY_YOUNG_BRAIN = '/admin/manage-company/young-brain';

export const MANAGE_VENDORS = '/admin/vendors';
export const MANAGE_STUDENTS = '/admin/students';
export const MANAGE_CONSULTANTS = '/admin/consultants/list';
export const MANAGE_CONSULTANTS_ONBOARDING_REQUEST =
  '/admin/consultants/onboarding-requests';
export const MANAGE_CONSULTANTS_SEND_PR =
  '/admin/consultants/onboarding-requests/send-pr';
export const MANAGE_CONSULTANTS_GENERATE_WO =
  '/admin/consultants/onboarding-requests/generate-work-order';
export const MANAGE_CONSULTANTS_PURCHASE_REQUISITION =
  '/admin/consultants/purchase-requisitions';
export const MANAGE_CONSULTANTS_CONTRACT = '/admin/consultants/contracts';
export const MANAGE_CONSULTANTS_PURCHASE_ORDER =
  '/admin/consultants/purchase-orders';
export const MANAGE_CONSULTANTS_WORK_ORDER = '/admin/consultants/work-orders';
export const REPORTS = '/admin/reports';
export const SETTINGS = '/admin/settings';
export const SIGNOUT = '/app/signout';
export const APP = '/app';
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/app/forgot-password';
export const RESET_PASSWORD = '/app/reset-password';

//Manage Company
export const MANAGE_COMPANY_IT_MAP = '/admin/manage-company/subscribes'
export const MANAGE_COMPANY_PLANS='/admin/manage-company/plans';
export const MANAGE_COMPANY_USERS = '/admin/manage-company/users';
export const MANAGE_COMPANY_JOB_POSTED = '/admin/manage-company/job-posted';
export const MANAGE_COMPANY_SEMiNAR_POSTED = '/admin/manage-company/seminar-posted';
export const MANAGE_COMPANY_INTERNSHIP_POSTED = '/admin/manage-company/internship-posted';

export const MANAGE_STUDENTS_YOUNG_BRAIN =
  '/admin/manage-students/young-brain';
export const MANAGE_STUDENTS_INTERNSHIPS = '/admin/manage-students/internship';

//Manage University
export const MANAGE_UNIVERSITY_USERS = '/admin/manage-university/users';

//Manage Users
export const MANAGE_USERS = '/admin/manage-users';