export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const AUTH_API_ENDPOINT = process.env.REACT_APP_AUTH_API_ENDPOINT;

export const baseEndpoint = api => API_ENDPOINT + api;
export const authBaseEndpoint = api => AUTH_API_ENDPOINT + api;

// Subcriptions Endpoints
export const getAllSubscriptionPlansApi = () =>
  baseEndpoint('/manage/university/findAllSubscriptionPlans');

export const createSubscriptionPlanApi = () =>
  baseEndpoint('/manage/university/createSubscriptionPlan');

export const getAllApplicationsApi = () =>
  baseEndpoint('/search/filter/application/list');

export const jobsApi = () => baseEndpoint('/companyData/jobs');

// Dashboard Endpoints
export const getAllPostedJobs = () => baseEndpoint('/posted-jobs');
export const getPostedJobDetails = id => baseEndpoint(`/posted-jobs/${id}`);

// Auth Endpoints
export const loginUserApi = () => authBaseEndpoint('/auth/login');

// Setting Endpoints
export const getUserSettingsApi = (params = '') =>
  baseEndpoint('/settings') + params;
export const updateUserSettingsApi = () => baseEndpoint('/settings');

//Manage Users
export const getAllUsersApi = (params = '') => baseEndpoint('/users') + params;
export const addUserApi = () => baseEndpoint('/users');
export const editUserApi = (id) => baseEndpoint(`/editUser/${id}`);
export const deleteUserApi = (id) => baseEndpoint(`/deleteUser/${id}`);

//Manage Students
export const getAllYounbrainStudentsApi = (params = '') => baseEndpoint('/manage/students/YOUNG_BRAIN_APP/list') + params;
export const getAllInternshipStudentsApi = (params = '') => baseEndpoint('/manage/students/INTERNSHIP_APP/list') + params;
export const blockAndUnblockManageStudentsApi = (id, params = '') => baseEndpoint(`/manage/students/${id}/action`) + params;

//Filters
export const getAllApplicationListApi = () => baseEndpoint("/search/filter/application/list")
export const getAllRolesListApi = () => baseEndpoint("/search/filter/role/list")
export const getAllAplicablePlansListApi = () => baseEndpoint("/search/filter/planApplicable/list")
export const getAllPlanDurationListApi = () => baseEndpoint("/search/filter/planDuration/list")

//Manage Univerties
export const getUnivertiesListApi = (params = '') => baseEndpoint('/manage/university/list') + params;
export const blockAndUnblockUniversitySubscriberApi = (id, params = '') => baseEndpoint(`/manage/university/subscriber/${id}`) + params;

//Manage Company
export const getAllSubscribersListApi = (params = '') => baseEndpoint('/manage/company/subscriber/findall') + params;


