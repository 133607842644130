import {
  AccountBalanceWalletOutlined,
  DashboardRounded,
  DataObjectOutlined,
  DataUsageOutlined,
  Groups2Outlined,
  LogoutOutlined,
  SettingsSuggestRounded
} from '@mui/icons-material';
import {
  DASHBOARD,
  MANAGE_USERS,
  MANAGE_COMPANY_INTERNSHIP_POSTED,
  MANAGE_COMPANY_IT_MAP,
  MANAGE_COMPANY_JOB_POSTED,
  MANAGE_COMPANY_PLANS,
  MANAGE_COMPANY_SEMiNAR_POSTED,
  MANAGE_COMPANY_USERS,
  MANAGE_STUDENTS_INTERNSHIPS,
  MANAGE_STUDENTS_YOUNG_BRAIN,
  MANAGE_SUBSCRIPTIONS_CONFIGURE,
  MANAGE_SUBSCRIPTIONS_PLANS,
  MANAGE_UNIVERSITY_USERS,
  SETTINGS,
  SIGNOUT
} from './routes';

export const APP_NAME = "AA";

export const APP_BAR_HEIGHT = 60;
export const APP_SIDEBAR_WIDTH = 230;
export const DRAWER_WIDTH = 230;
export const DRAWER_WIDTH_MINI = 90;
export const DEFAULT_PAGINATION_PAGE_NO = 1;
export const DEFAULT_PAGINATION_COUNT = 10;
export const DEFAULT_PAGINATION = {
  data: [],
  pagination: {
    currentPage: DEFAULT_PAGINATION_PAGE_NO,
    count: DEFAULT_PAGINATION_COUNT,
    totalRecords: 0,
    nextPage: false,
    prevPage: false,
  }
};
export const DEFAULT_SORTING = 'ASC';
export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please refresh and try again.';
export const DEFAULT_DELETING_MESSAGE = 'Deleting...';
export const DEFAULT_DELETED_MESSAGE = 'Successfully deleted';
export const DEFAULT_APP_NAME = "ADMIN_APP";

export const DRAWER_MENU = [
  {
    id: 1,
    title: 'Dashboard',
    url: DASHBOARD,
    icon: DashboardRounded,
  },

  {
    id: 2,
    title: 'Manage ERP Tohama',
    icon: Groups2Outlined,
    items: [
      {
        id: 1,
        title: 'Manage Users',
        url: MANAGE_USERS
      }
    ]
  },

  {
    id: 3,
    title: 'Manage Student',
    icon: DataObjectOutlined,
    items: [
      {
        id: 1,
        title: 'Young Brain',
        url: MANAGE_STUDENTS_YOUNG_BRAIN,
      },
      {
        id: 2,
        title: 'Internship',
        url: MANAGE_STUDENTS_INTERNSHIPS,
      },
    ],
  },

  // {
  //   id: 3,
  //   title: 'Manage Students',
  //   url: '',
  //   icon: Groups2Outlined,
  // },
  {
    id: 4,
    title: 'Manage University',
    icon: AccountBalanceWalletOutlined,
    items: [
      {
        id: 1,
        title: 'Plans',
        url: MANAGE_SUBSCRIPTIONS_PLANS,
      },
      // {
      //   id: 1,
      //   title: 'Subscribers',
      //   url: MANAGE_SUBSCRIPTIONS_SUBSCRIBERS,
      // },
      {
        id: 2,
        title: 'List Of Universities',
        url: MANAGE_SUBSCRIPTIONS_CONFIGURE,
      },
      {
        id: 3,
        title: 'Manage Users',
        url: MANAGE_UNIVERSITY_USERS,
      },

    ],
  },
  {
    id: 5,
    title: 'Manage Company',
    icon: DataUsageOutlined,
    items: [
      // {
      //   id: 1,
      //   title: 'Young Brain',
      //   url: MANAGE_COMPANY_YOUNG_BRAIN,
      // },
      {
        id: 1,
        title: 'Plans',
        url: MANAGE_COMPANY_PLANS,
      },
      {
        id: 2,
        title: 'Subscribers',
        url: MANAGE_COMPANY_IT_MAP,
      },
      {
        id: 3,
        title: 'Manage Users',
        url: MANAGE_COMPANY_USERS,
      },
      {
        id: 4,
        title: 'Job Posted',
        url: MANAGE_COMPANY_JOB_POSTED,
      },
      {
        id: 5,
        title: 'Seminar Posted',
        url: MANAGE_COMPANY_SEMiNAR_POSTED,
      },
      {
        id: 6,
        title: 'Internship Posted',
        url: MANAGE_COMPANY_INTERNSHIP_POSTED,
      },
    ],
  },
  
  // {
  //   id: 7,
  //   title: 'Manage Vendor',
  //   icon: Groups2Outlined,
  //   url: MANAGE_VENDORS,
  // },
  // {
  //   id: 7,
  //   title: 'Student',
  //   url: MANAGE_STUDENTS,
  //   icon: Groups2Outlined,
  // },
  // {
  //   id: 8,
  //   title: 'Manage Consultant',
  //   icon: Groups2Outlined,
  //   items: [
  //     {
  //       id: 1,
  //       title: 'List Of Consultant',
  //       url: MANAGE_CONSULTANTS,
  //     },
  //     {
  //       id: 1,
  //       title: 'Onboarding Request',
  //       url: MANAGE_CONSULTANTS_ONBOARDING_REQUEST,
  //     },
  //     {
  //       id: 1,
  //       title: 'List of Purchase Requisition',
  //       url: MANAGE_CONSULTANTS_PURCHASE_REQUISITION,
  //     },
  //     {
  //       id: 1,
  //       title: 'List of Contract',
  //       url: MANAGE_CONSULTANTS_CONTRACT,
  //     },
  //     {
  //       id: 1,
  //       title: 'List of Purchase Order',
  //       url: MANAGE_CONSULTANTS_PURCHASE_ORDER,
  //     },
  //     {
  //       id: 1,
  //       title: 'List of Work Order',
  //       url: MANAGE_CONSULTANTS_WORK_ORDER,
  //     },
  //   ],
  // },
  // {
  //   id: 8,
  //   title: 'Reports',
  //   url: REPORTS,
  //   icon: AssessmentOutlined,
  // },
  {
    id: 8,
    title: 'Settings / 設定',
    url: SETTINGS,
    icon: SettingsSuggestRounded,
  },
  {
    id: 9,
    title: 'Signout',
    url: SIGNOUT,
    icon: LogoutOutlined,
  },
];

export const tableHeaderStyles = {
  fontSize: '0.65rem',
  fontFamily: 'Poppins',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 1,
  color: '#999',
  fontWeight: 600,
};

export const tableTextStyles = {
  fontFamily: 'Poppins',
  fontSize: 'xs',
  lineHeight: 1,
  fontWeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: '#1A1A1A',
  flex: 1,
};

export const tableTextEllipsisStyles = {
  fontFamily: 'Poppins',
  fontSize: 'xs',
  lineHeight: 1,
  fontWeight: 'normal',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: '#1A1A1A',
  flex: 1,
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

export const tableTextLinkStyles = {
  fontFamily: 'Poppins',
  fontSize: 'xs',
  lineHeight: 1,
  fontWeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 1,
  textDecoration: 'underline',
  cursor: 'pointer',
  color: 'blue.500',
};

export const paginationState = {
  previous: null,
  next: null,
  noOfPageItems: DEFAULT_PAGINATION_COUNT,
  page: DEFAULT_PAGINATION_PAGE_NO,
  total: DEFAULT_PAGINATION_COUNT * (DEFAULT_PAGINATION_PAGE_NO + 1),
  pageCount: DEFAULT_PAGINATION_PAGE_NO + 1,
  hasMore: false,
  loading: true,
  data: [],
};

export const DEFAULT_EMPTY_INDICATOR='-';

export const DEFAULT_LANGUAGE = 'en';

export const LANGUAGES = [
  {
    text: 'Japanese',
    value: 'ja',
  },
  {
    text: 'English',
    value: 'en',
  },
];

export const menuJapStyles = {
  fontFamily: 'Noto Sans JP',
  fontSize: 14,
  fontWeight: 700,
};

export const subMenuJapStyles = {
  fontFamily: 'Noto Sans JP',
  fontSize: 12,
  fontWeight: 600,
};


export const ALL_FILTER_OPTION = {
  id: '-1',
  label: 'All'
}