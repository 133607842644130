import axios from 'axios';
import { LS_ACCESS_TOKEN } from 'utils/constants/localStorage';
const axiosInstance = axios.create({
  // baseURL: environments.serverHost,
});

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled
    ? false
    : true;
};

const requestHandler = request => {
  if (isHandlerEnabled(request)) {
    // Modify request here
    request.headers['Access-Control-Allow-Origin'] = '*';
    // const token = localStorage.getItem(LS_ACCESS_TOKEN);
    // if (token) {
    //   request.headers['authorization'] = `Bearer ${token}`;
    // }
  }
  return request;
};

axiosInstance.interceptors.request.use(request => requestHandler(request));

const errorHandler = error => {
  if (error.response.status === 401) {
  }
  return Promise.reject(error.response.data);
};

const successHandler = response => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
    return response.data;
  } else return response;
};

axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

export default axiosInstance;
