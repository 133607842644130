import common from './common.json';
import sidebar from './sidebar.json';
import login from './login.json';
import admin from './admin.json';
import app from './app.json';
import settings from './settings.json';
import role from './role.json';
import subscriptions from './subscriptions.json';
import reports from './reports.json';
import vendor from './vendor.json';
import company from './company.json';
import student from './student.json';
import consultants from './consultants.json';
import dashboard from './dashboard.json';

const en = {
  app,
  admin,
  common,
  dashboard,
  login,
  sidebar,
  settings,
  role,
  subscriptions,
  reports,
  vendor,
  company,
  student,
  consultants,
};
export default en;
