import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE } from 'utils/constants/app';
import { LS_LANGUAGE } from 'utils/constants/localStorage';
import en from './en';
import ja from './ja';

const translationData = {
  en,
  ja,
};

const lng = localStorage.getItem(LS_LANGUAGE) || DEFAULT_LANGUAGE;

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: translationData,
  lng,
});

export default i18n;
