export const LS_USER_ID = 'USER_ID';
export const LS_LANGUAGE = 'LANGUAGE';
export const LS_COMPANY_NAME = 'COMPANY_NAME';
export const LS_COMPANY_ID = 'COMPANY_ID';
export const LS_EXPIRATION_TOKEN = 'EXPIRATION_TOKEN';
export const LS_TOKEN = 'TOKEN';

export const setLocalStorageItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  
  export const getLocalStorageItem = (key, defaultValue = null) => {
    const storedValue = localStorage.getItem(key);
  
    if (storedValue === null || storedValue === undefined) {
      return defaultValue;
    }
  
    return JSON.parse(storedValue);
  };
